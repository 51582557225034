// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Knowledge from 'components/Contentful/Knowledge'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Treten Sie in Kontakt mit uns"
        meta={[
          { name: 'description', content: 'Nehmen Sie Kontakt zu uns auf. Ob per Mail, Telefon oder Kontaktformular - wir sind immer für Sie erreichbar. Jetzt kontaktieren!' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]}></Helmet>
      <div
          className='bg__flex--white'
          key='2trudQuTR4ps2PUo5frYvJ-5oTWzYyJRmuWuiiwEOewwe'
          id='kontaktaufnahme'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "5oTWzYyJRmuWuiiwEOewwe",
    "updatedAt": "2020-08-21T07:46:16.149Z",
    "title": "Wir beraten Sie gerne!",
    "description": "<p>Bestätigung: Wir melden uns schnellst möglich bei Ihnen, um alle weiteren Schritte zu besprechen.</p>\n",
    "link": {
        "type": "link",
        "id": "3zWOEVaaFG0umMMgwaA0ek",
        "updatedAt": "2018-11-30T09:09:57.202Z",
        "label": "Team",
        "page": {
            "slug": "team"
        }
    },
    "anchorName": "kontaktaufnahme",
    "details": []
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
